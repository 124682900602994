import React, { useState } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const HeaderSection = ({ about, emails, logo }) => {
  const [active, setActive] = useState("");

  return (
    <Wrapper>
      <div className="logo-wrapper">
        <div className="logo">
          <img src={logo} role={"Logo"} />
        </div>
      </div>

      <div className="button-wrapper">
        <div className="section left">
          <button
            type="button"
            className={active === "about" ? "active" : ""}
            onClick={() => setActive(old => (old === "about" ? "" : "about"))}
          >
            /about
          </button>
          <div className={active === "about" ? "active content" : "content"}>
            <ReactMarkdown source={about} />
          </div>
        </div>
        <div className="section right">
          <button
            type="button"
            className={active === "contact" ? "active" : ""}
            onClick={() =>
              setActive(old => (old === "contact" ? "" : "contact"))
            }
          >
            /contact
          </button>
          <div className={active === "contact" ? "active content" : "content"}>
            {emails.map((x: string) => (
              <React.Fragment key={x}>
                <a
                  href={`mailto:${x}`}
                  onClick={e => active !== "contact" && e.preventDefault()}
                >
                  {x}
                </a>
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;

  .logo-wrapper {
    display: grid;
    grid-template-columns: 1fr 7em 1fr;
    padding-top: 3em;

    .logo {
      display: flex;
      justify-content: center;
      grid-column: 2;
    }
    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 550px) {
      grid-template-columns: 1fr 6em 1fr;
    }
  }

  .button-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: -7em;
    padding: 0 1em;
  }

  p {
    max-width: 26em;
    line-height: 25px;
    letter-spacing: 0.4px;
  }

  a {
    color: white;
    text-decoration: none;
    padding-bottom: 0.5em;
  }
  a:visited {
    color: white;
  }
  a:hover {
    color: #c19898;
  }

  .section {
    flex: 0 1 50%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    padding-top: 2.5em;
  }
  .section > div {
    transition: 0.3s ease-in-out;
    opacity: 0;
    max-width: 30vw;
  }
  .section > div.active {
    opacity: 1;
  }
  .section.left {
    align-items: flex-start;
    padding-left: 2em;
  }
  .section.right {
    align-items: flex-end;
    padding-right: 2em;
    text-align: right;
  }
  .section.right button {
    transform: translateX(-5em);
  }
  .section.right button.active {
    transform: translateX(0em);
  }

  button {
    padding: 0em;
    padding-bottom: 1.5em;
    transition: 0.3s ease-in-out;
    transform: translateX(5em);
    font-weight: normal;
    text-transform: uppercase;
    color: white;
    font-size: 16px;
    background-color: transparent;
    border: none;
    outline: none;
    letter-spacing: 2.3px;
  }

  button.active {
    transform: translateX(0em);
    color: #c19898;
  }

  @media (max-width: 800px) {
    padding: 0 0.5em;
    box-sizing: border-box;
    .section > div {
      max-width: 100%;
    }
    .button-wrapper {
      margin-top: -1.2em;
      padding: 0;
    }
    button {
      transform: translateX(0em) translateY(-6em);
    }
    .section.right button {
      transform: translateX(0em) translateY(-6em);
    }
  }

  @media (max-width: 550px) {
    .button-wrapper {
      grid-template-rows: 0em;
    }
    .section {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .section.left {
      padding-right: 2em;
      & button {
        text-align: left;
      }
    }
    .section.right {
      padding-left: 2em;
      & button {
        text-align: right;
      }
    }
    & button {
      height: 2em;
      width: 1.5em;
      overflow: hidden;
    }
    & button.active {
      width: 7em;
    }

    button {
      transform: translateX(0em) translateY(-5em);
    }
    .section.right button {
      transform: translateX(0em) translateY(-5em);
    }
  }
`;

export default HeaderSection;
