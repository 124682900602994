import React, { useState, useEffect } from "react";
import styled from "styled-components";

import ProgressiveVideo from "../components/ProgressiveVideo";
import Header from "../components/Header";
import InstagramIcon from "../components/InstagramIcon";

const useScreenWidth = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);

    let timer;
    const handleResize = () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setWidth(window.innerWidth);
        timer = null;
      }, 1000);
    };

    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
};

const LandingPage = ({ about, emails, logo }) => {
  const screenWidth = useScreenWidth();

  const size = [1920, 1408, 1080, 704, 480].sort(
    (a, b) => Math.abs(a - screenWidth) - Math.abs(b - screenWidth)
  )[0];
  const backgroundLinks = {
    video: `https://general-web-content-0000.s3.eu-west-2.amazonaws.com/${size}.mp4`,
    pre: "https://general-web-content-0000.s3.eu-west-2.amazonaws.com/320.mp4"
  };

  return (
    <div>
      <Header emails={emails} about={about} logo={logo} />
      <ProgressiveVideo {...backgroundLinks} />
      <a href="https://www.instagram.com/tavasolianstudio/" target="_blank">
        <InstaIcon />
      </a>
    </div>
  );
};

const InstaIcon = styled(InstagramIcon)`
  fill: white;
  position: fixed;
  bottom: 3em;
  left: 8em;
  width: 1.7em;

  @media (max-width: 800px) {
    left:2em;
  }
`;
export default LandingPage;
