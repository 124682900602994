import React from 'react';
import { graphql } from "gatsby"

import LandingPage from '../components/LandingPage';
import Layout from '../components/Layout';

const Page = ({ data: { landing, metadata } }) => {
  return (
    <Layout metadata={metadata}>
      <LandingPage {...landing} />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    metadata:yaml(parent: {internal: {description: {regex: "/metadata/"}}}) {
      description
      title
    }
    landing:yaml(parent: {internal: {description: {regex: "/landing/"}}}) {
      about
      emails
      logo
    }
  }
`

export default Page;

